<template>
  <v-container
    v-if="data"
    class="products-list-page row"
    fluid>
    <v-col
      class="count-stock-warehouses rounded pa-5"
      cols="12">
      <v-row class="d-flex justify-space-between">
        <v-col cols="6">
          <h1>
            นับของ
            <span v-if="data.isAllProduct">( นับทั้งหมด )</span>
            <span v-else>( นับแค่บางส่วน )</span>
          </h1>
          <p class="mt-2">
            คลังสินค้า {{ data.warehouse.name }}
          </p>
          <p> Note :{{ data.note }}</p>
        </v-col>
        <v-col cols="4">
          <v-form
            ref="form"
            v-model="valid">
            <v-text-field
              v-model="location"
              placeholder="ห้องสต็อก, หน้าร้าน, อื่น ๆ"
              :rules="requiredRule"
              label="โลเคชั่น"
              full-width
              outlined
              dense />
          </v-form>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-end">
          <v-btn
            color="success"
            :loading="loading"
            x-large
            @click="submit()">
            Submit
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="productQr"
            placeholder="คลิ๊กที่ช่องนี้เพื่อสแกน QR Code"
            hide-details
            autofocus
            outlined
            dense
            @keydown.enter="scanQr" />
        </v-col>
        <v-col cols="2">
          <v-btn
            color="success"
            width="full"
            block
            :loading="loading"
            @click="dowloadCsv()">
            รายการสินค้า CSV
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="2"
      class="count-stock-warehouses rounded pa-2 mt-5">
      <h2>Scan Logs</h2>
      <div class="product-list">
        <ol>
          <li
            v-for="(item, index) of scanLogs"
            :key="`scan-log-${index}`"
            :class="item.isFound ? '' : 'cl-red'">
            {{ item.code }} <a
              style="color: red;"
              @click="removeScanLog(index)"> x </a>
          </li>
        </ol>
      </div>
    </v-col>
    <v-col
      class="count-stock-warehouses rounded pa-5 mt-5"
      cols="10">
      <div class="product-list">
        <div
          v-for="(product, i) in products"
          :key="i">
          <div class="product-header">
            <div
              class="px-2 d-flex justify-space-between w-full"
              :class="product.isNotFound ? 'cl-red' : ''">
              {{ product.name }}
            </div>
          </div>
          <div class="product-content pl-4 py-4">
            <v-col class="row">
              <v-col
                cols="2"
                class="pa-0 text-center text-bold">
                SKU CODE
              </v-col>
              <v-col
                cols="1"
                class="pa-0 text-center text-bold">
                COLOR
              </v-col>
              <v-col
                cols="1"
                class="pa-0 text-center text-bold">
                SIZE
              </v-col>
              <v-col
                cols="1"
                class="text-right pa-0 text-center text-bold">
                PRICE
              </v-col>
              <v-col
                cols="1"
                class="text-right pa-0 d-flex justify-end text-bold">
                COUNT
              </v-col>
            </v-col>
            <v-col
              v-for="(sku, index) of product.skus"
              :key="`${i}-${index}`"
              class="row mt-4">
              <v-col
                cols="2"
                class="pa-0 text-center">
                {{ sku.code || '' }}
              </v-col>
              <v-col
                cols="1"
                class="pa-0 text-center">
                {{ sku.color || '' }}
              </v-col>
              <v-col
                cols="1"
                class="pa-0 text-center">
                {{ sku.size }}
              </v-col>
              <v-col
                cols="1"
                class="text-right pa-0">
                {{ sku.price | showNumberFormat() }}
              </v-col>
              <v-col
                cols="1"
                class="text-right pa-0 d-flex justify-end">
                {{ sku.count }}
              </v-col>
            </v-col>
          </div>
        </div>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import CountStockProvider from '@/resources/CountStockProvider'
import ProductProvider from '@/resources/ProductProvider'
import { ExportToCsv } from 'export-to-csv'
import { mapGetters } from 'vuex'

const CountStockService = new CountStockProvider()
const ProductService = new ProductProvider()

export default {
  data () {
    return {
      loading: false,
      data: null,
      valid: false,
      location: '',
      productQr: '',
      products: [],
      scanLogs: [],
      requiredRule: [
        (v) => !!v || 'กรุณากรอกช่องนี้'
      ]
    }
  },
  computed: {
    ...mapGetters({
      storeSetting: 'Store/storeSetting'
    }),
    id () {
      return this.$route.params.id || null
    },
    warehouse () {
      return this.data?.warehouse || null
    },
    sumCount () {
      return this.data?.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.count + ss, 0), 0) || 0
    },
    sumStock () {
      return this.data?.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.stock + ss, 0), 0) || 0
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    dowloadCsv () {
      const options = {
        filename: `${this.data.id}-${this.data.note}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const data = []

      this.data.products.forEach((product) => {
        product.skus.forEach((sku) => {
          data.push({
            name: product.name,
            model: product.model,
            code: sku.code,
            color: sku.color,
            size: sku.size,
            price: sku.price
          })
        })
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    imgSrcCompute (src) {
      return src || 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
    },
    calStock (sku) {
      const temp = sku.stock
      return temp > 0 ? temp : 0
    },
    exportNotFoundProduct () {
      const options = {
        filename: 'PRODUCT-NOTFOUND',
        showLabels: false,
        useKeysAsHeaders: true
      }

      const scanLogs = this.scanLogs.filter((sl) => !sl.isFound)

      if (scanLogs.length === 0) return

      const data = []

      for (const scanLog of scanLogs) {
        const index = data.findIndex((d) => d.code === scanLog.code)
        if (index === -1) {
          data.push({
            code: scanLog.code,
            amount: 1
          })
        } else {
          data[index].amount += 1
        }
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    async submit () {
      this.$refs.form.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const results = await CountStockService.createCountStockDetail({
          ...this.data,
          location: this.location,
          countStock: this.data.id,
          products: this.products
        })
        if (results) {
          this.$router.push({ name: 'CountStockList' })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[${error.code}] ${error.message}`,
          type: 'error'
        })
      } finally {
        this.exportNotFoundProduct()
        this.loading = false
      }
    },
    removeScanLog (ind) {
      const [scanLog] = this.scanLogs.splice(ind, 1)
      for (let pInd = 0; pInd < this.products.length; pInd++) {
        const index = this.products[pInd].skus.findIndex((sku) => sku.code.trim() === scanLog.code.trim())
        if (index !== -1) {
          this.products[pInd].skus[index].count--
        }
      }
    },
    async scanQr () {
      const codes = this.productQr.trim().split(`${this.storeSetting.webUrl}/`)
      const code = codes[codes.length - 1]

      if (code === '') return

      let isFound = false
      let foundProduct = null

      for (let pInd = 0; pInd < this.data.products.length; pInd++) {
        const index = this.data.products[pInd].skus.findIndex((sku) => sku.code.trim() === code.trim())
        if (index !== -1) {
          isFound = true
          foundProduct = {
            ...this.data.products[pInd],
            skus: {
              ...this.data.products[pInd].skus[index],
              count: 1
            }
          }
          break
        }
      }

      if (isFound) {
        const productIndex = this.products.findIndex((p) => p.id === foundProduct.id)
        if (productIndex === -1) {
          this.products.push({
            ...foundProduct,
            skus: [foundProduct.skus]
          })
        } else {
          const skuIndex = this.products[productIndex].skus.findIndex((s) => s.id === foundProduct.skus.id)
          if (skuIndex === -1) {
            this.products[productIndex].skus.push(foundProduct.skus)
          } else {
            this.products[productIndex].skus[skuIndex].count += 1
          }
        }
      } else {
        let isFoundPd = false
        for (let pInd = 0; pInd < this.products.length; pInd++) {
          const index = this.products[pInd].skus.findIndex((sku) => sku.code.trim() === code.trim())
          if (index !== -1) {
            isFoundPd = true
            this.products[pInd].skus[index].count += 1
            break
          }
        }

        if (!isFoundPd) {
          const { data } = await ProductService.getProducts({
            page: 1,
            limit: 1,
            search: code
          })
          const productData = data.results[0]
          if (productData) {
            const skuData = productData.skus.find((s) => s.code === code)
            if (skuData) {
              const stock = skuData.stock.find((st) => st.warehouse.id === this.warehouse.id)
              this.products.push({
                id: productData.id,
                skus: [{
                  id: skuData.id,
                  code: skuData.code,
                  stock: stock?.onHandQty || 0,
                  reserve: stock?.onReservedQty || 0,
                  count: 1,
                  price: skuData.price,
                  option: skuData.option,
                  color: skuData.color?.name || '',
                  size: skuData.size?.name || ''
                }],
                isNotFound: true,
                name: productData.name,
                variant: productData.variant,
                model: productData.model,
                aliasId: productData.aliasId,
                photoUrls: productData.photoUrls,
                brand: productData.brand
              })
            }
          }
        }
      }

      this.scanLogs.push({
        code,
        isFound
      })
      this.productQr = ''
    },
    getHightlight (sku) {
      if (this.calStock(sku) > sku.count) { return 'less-stock' }
      if (this.calStock(sku) < sku.count) { return 'grater-stock' }
      return ''
    },
    async fetchData () {
      try {
        this.loading = true
        const { data } = await CountStockService.getById(this.id)
        this.data = data
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch data]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.cl-red {
  color: #fc7a7a;
}
.less-stock {
  background-color: #fc7a7a;
}
.grater-stock {
  background-color: #7ae0fc;
}
.count-stock-warehouses {
  width: 100%;
  background-color: white;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
}
.product-header {
  width: 100%;
  padding: 10px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
}
.product-img-wrapper {
  padding: 2px;
  width: 80px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ababab;
}
.product-list {
  padding: 10px;
  height: calc(100vh - 365px);
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
</style>
